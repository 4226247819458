<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="dateArr"
        type="datetimerange"
        :default-time="['00:00:00', '23:59:59']"
        align="right"
        value-format="yyyy-MM-dd HH:mm:ss"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="changeDate"
        class="filter-item"
        style="display: inline-flex"
      ></el-date-picker>
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入用户名/电话"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <!-- <el-select
        v-model="listQuery.is_normal"
        placeholder="是否冻结"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已冻结" :value="0" />
      </el-select> -->

      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <div class="filter-container">会员数量：{{ total || 0 }}</div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="ID" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="头像" min-width="110" align="center">
        <template slot-scope="scope">
          <img
            v-if="scope.row.avatar"
            :src="scope.row.avatar"
            class="user-avatar"
          />
        </template>
      </el-table-column>
      <el-table-column label="昵称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.nickname }}
        </template>
      </el-table-column>
      <el-table-column label="联系电话" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.phone }}
        </template>
      </el-table-column>
      <el-table-column label="首次开通时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.vip_first_open_at }}
        </template>
      </el-table-column>
      <el-table-column label="会员到期时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.vip_expire_at }}
        </template>
      </el-table-column>
      <el-table-column label="已购买会员费用" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.vip_buy_money ? scope.row.vip_buy_money : 0 }}
        </template>
      </el-table-column>
      
      <el-table-column label="已赠送会员时长" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.back_send_vip_day_num ? scope.row.back_send_vip_day_num : 0 }}天
        </template>
      </el-table-column>

      <el-table-column label="修改会员到期时间" 
       width="200"
        class-name="small-padding fixed-width"
        fixed="right"
      align="center">
        <template slot-scope="scope">
         <el-button
            v-if="scope.row.is_normal == 1"
            size="mini"
            type="primary"
            @click="member(scope.row.id, scope.row.vip_expire_at)"
            >修改会员到期时间</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
  title="修改会员到期时间"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
   <div class="block">
    <!-- <span class="demonstration">默认</span> -->
    <el-date-picker
      v-model="form.vip_expire_at"
      type="date"
      placeholder="选择日期">
    </el-date-picker>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="memberdata()">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import downloadExcel from "vue-json-excel";
export default {
  components: { downloadExcel },
  data() {
    return {
        form: {
        user_id: "",
        vip_expire_at: "",
      },
      dialogVisible: false,
      list: null,
      total: null,
      listLoading: true,
      total_sales_volume: null,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        is_normal: "",
        start_at: "",
        end_at: "",
        is_vip:1
      },
      dateArr:[]
    };
  },
  created() {
    this.getList();
  },
    watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0];
        this.listQuery.end_at = newVal[1];
      } else {
        this.listQuery.start_at = "";
        this.listQuery.end_at = "";
      }
    },
  },
  methods: {
     memberdata(){
        var d = new Date(this.form.vip_expire_at); 
        d=d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' +'23' + ':' +'59'+ ':'+'59'; 
        console.log(d);
          request({
            url: "/api/backend/user/changeVipExpireTime",
            method: "post",
            data: { user_id:this.form.user_id,vip_expire_at:d}
          })
            .then((response) => {
              this.dialogVisible = false;
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
              this.form.vip_expire_at = '';
            })
            .catch((err) => {
              this.btnLoading = false;
            });
     },
     member(id,data){
        this.dialogVisible = true;
        this.form.user_id = id;
        this.form.vip_expire_at = data;
        console.log(data);
        console.log(111);
        console.log(this.form.id);
        console.log(this.dialogVisible); 
     },
      handleClose(done) {
            done();
      },
    changeDate(data) {
      this.dateArr=data
      this.listQuery.start_at = data[0];
      this.listQuery.end_at = data[1];
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/user/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
